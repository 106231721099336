/*=========================================================================================
  File Name: moduleRegionMutations.js
  Description: Region Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.regions.unshift(item)
  },
  SET_Region(state, regions) {
    state.regions = regions
  },
  UPDATE_Region(state, Region) {
    const RegionIndex = state.regions.findIndex((p) => p.ID == Region.ID)
    Object.assign(state.regions[RegionIndex], Region)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.regions.findIndex((p) => p.ID == itemId)
    state.regions.splice(ItemIndex, 1)
},
}
